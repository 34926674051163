import React from 'react'

import './Button.css';
import whatsapp_icon from "../../img/whastapp.png";

const Button = ({mydata}) => {
  return (
    <div>
        <a href={mydata.phone} target="_blank" rel='noreferrer' className="btn btn-primary button me-2">
            <img src={whatsapp_icon} alt="" className='whatsapp_icon'/>Contactar
        </a>
    </div>
  )
}

export default Button