import React from 'react';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Intro from './Components/Intro/Intro';

function App() {
  const AppData = function(phone) {
    return {phone};
  }
  const mydata = new AppData();
  mydata.phone = "https://wa.me/573186435636?text=Hola, quiero aprender sobre negocios digitales";



  return (
    <div className="App">
        <Navbar mydata={mydata}></Navbar>
        <Intro mydata={mydata}></Intro>
    </div>
  );
}

export default App;
