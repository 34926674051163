import React from 'react'
import './Intro.css';

import linkedin from "../../img/linkedin.png";
import instagram from "../../img/instagram.png";
import tiktok from "../../img/tiktok.png";

import boy from "../../img/boy.png";
import Button from '../Button/Button';


const Intro = ({mydata}) => {
    return (
        <div className="container-fluid col-xl-10 col-xxl-8 px-4 mb-4 Intro" id="Intro">
            <div className="row align-items-center g-lg-5 pt-5">
                <div className="col-lg-7 text-center text-lg-start">
                    <h1 className="display-5 fw-bold lh-1 mb-3"><strong>Vivir de internet es posible</strong></h1>
                    <h2 className="display-6 fw-bold lh-1 mb-3">Estas son mis  razones por las que comencé a crear negocios digitales</h2>
                    <ul>
                        <li>No me gustaba levantarme temprano ni cumplir horario.</li>
                        <li>Quería trabajar desde cualquier parte del mundo.</li>
                        <li>Quería decidir cuanto podía ganar y en que monedas a nivel mundial.</li>
                        <li>No quería dolores de cabezas con empleados.</li>
                        <li>No quería invertir mucho dinero.</li>
                        <li>No quería entregarle mi dinero a otros.</li>
                        <li>Quería controlar el destino de cada proyecto.</li>
                    </ul>
                    <p>Ganar dinero en internet no es fácil, pero vale la pena intentarlo y cuando lo logrés vas a tener una sonrisa gigante.</p>
                    <p>Si quieres trabajar en internet y puedo ayudarte, te dejo mi contacto por whastapp.</p>

                    <br />

                    <div>
                        <a href="https://www.instagram.com/iohansandoval" className='me-2'>
                            <img src={instagram} alt="" className='icon-social mr-2'></img>
                        </a>
                        @iohansandoval
                    </div>

                    <div>
                        <a href="https://www.linkedin.com/in/iohan-sandoval-a244ab211/" className='me-2'>
                            <img src={linkedin} alt="" className='icon-social mr-2'></img>
                        </a>
                        iohan sandoval
                    </div>
                    <div>
                        <a href="https://www.tiktok.com/@iohansandoval" className='me-2'>
                            <img src={tiktok} alt="" className='icon-social mr-2'></img>
                        </a>
                        @iohansandoval
                    </div>
                    <br />
                    <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>


                    <Button mydata={mydata}></Button>
                </div>
                <div className="col-md-10 mx-auto col-lg-5 text-center">

                    <img src={boy} alt="" className='img-fluid i-boy my-4'/>

                </div>
            </div>
        </div>

    )
}

export default Intro