import React from 'react'
import './Navbar.css'
import Button from '../Button/Button';

const Navbar = ({mydata}) => {
  return (
    <div className="navbar-theme">
        <div className="container">
            <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4">
                <a href="/" className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none navbar-brand">
                    Iohan Sandoval
                </a>

                {/* <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                    <li><a href="/" className="nav-link px-2 link-secondary font-bold">Inicio</a></li>
                </ul> */}

                <div className="col-md-3 text-end">
                    <Button mydata={mydata}></Button>
                </div>
            </header>
        </div>
    </div>

)
}

export default Navbar